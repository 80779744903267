<template>
    <button v-target:my-modal v-bind="$attrs">{{$t('Save Filter As')}}</button>
    <OModal name="my-modal" @shown="focusNameInput">
        <div class="modal-dialog modal-lg"  >
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">{{$t('Save filter')}}</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" v-if="filterTemplates && filterTemplates.filterGroup">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="floatingInputName" v-model="filterTemplates.name" ref="nameRef" @focusin.stop>
                        <label for="floatingInputName">{{$t('Name')}}</label>
                    </div>
                   
            
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{$t('Close')}}</button>
                    <button type="button" class="btn btn-primary" @click="saveAs()" data-bs-dismiss="modal">{{$t('Save')}}</button>
                    
                </div>
            </div>
        </div>
    </OModal>

</template>
<script setup lang="ts">
    import {useAttrs, ref, type Ref} from 'vue';
    const props = defineProps({
        filterTemplates:Object
    });

    const nameRef : Ref<HTMLInputElement | null> = ref(null);

    const attrs = useAttrs();

     const saveAs = () =>{
        props.filterTemplates.primKey = null;
        props.filterTemplates.save().then(()=>{
           props.filterTemplates.filtersListDO.load();

        })
    }

    function focusNameInput() {
        nameRef.value?.focus();
    }
</script>